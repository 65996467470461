import { useState } from "react";
import { FaCaretRight, FaCaretDown } from "react-icons/fa";
import Kanbas from "./Kanbas";
import TextAnalysis from "./TextAnalysis";
import './style.css';
import { motion } from "framer-motion";

const initialProjectList = [
  { component: <Kanbas />, title: 'Full Stack Project - Kanbas', thumbnail: '/Images/Kanbas/fig_1.png', open: false },
  { component: <TextAnalysis />, title: 'Student Text Data Analysis', thumbnail: '/Images/TextAnalysis/message_app.png', open: false }
];

function Projects() {
  const [projectList, setProjectList] = useState(initialProjectList);

  const toggleProject = (title: string) => {
    setProjectList(projectList.map(project =>
      project.title === title ? { ...project, open: !project.open } : project
    ));
  };

  return(
    <div className="project-container">
      {projectList.map(project => (
        <div className="p-body">
          <h2 onClick={() => toggleProject(project.title)}>
            <div className="title">
              {project.open ? <FaCaretDown/> : <FaCaretRight/>}
              {project.title}
            </div>
          </h2>
          <hr />
          {project.open ? 
            project.component : 
            <img onClick={() => toggleProject(project.title)} 
              className="one-img" src={project.thumbnail}/>}
        </div>
      ))}
    </div>
  );
};

export default Projects;