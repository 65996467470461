import './style.css';

function Section1({ children, title }: {children: any, title: string}) {
  return( 
    <div className='section'>
      <h4>{title}</h4>
      <p>
        {children}
      </p>
    </div>
  );
};

export default Section1;