import { Route, Routes, useLocation } from "react-router-dom";
import { Navigation, HNavigation } from "./Navigation";
import AnimatedDownArrow from "./Components/Animation/DownArrow";
import About from "./About";
import Projects from "./Projects";
import Resume from "./Resume";
import "./style.css";

function PersonalSite() {
  const path = useLocation().pathname;
  const arrowVariants = {
    animate: {
      y: [0, 10, 0],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "loop" as const
      }
    }
  };

  return (
    <>
      <div className="d-block d-xl-none">
        <HNavigation/>
      </div>
      <div className="d-flex">
        <div className="d-none d-xl-block">
          <Navigation />
        </div>
        <div className="container-fluid" style={{flexGrow: 1}}>
          <div className="top-page">
            <span></span>
            <p>
              Welcome <br />
              I'm Evan
            </p>
          </div>
          <div className="scroll-banner">
            <hr />
            <h2>
              { (path !== "/Main")  ? 
                <div>
                  <AnimatedDownArrow />
                  Scroll Down 
                  <AnimatedDownArrow />
                </div> : 
                <div>
                  Select Something
                </div> }
            </h2>
            <hr />
          </div>
          <Routes>
            <Route path="/"/>
            <Route path="About" element={<About/>} />
            <Route path="Projects" element={<Projects/>} />
            <Route path="Resume" element={<Resume/>} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default PersonalSite