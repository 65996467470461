import './../style.css';
import Section from '../../Components/Section';

function TextAnalysis() {
  const IMAGE_PATH = '/Images/TextAnalysis/';

  return (
    <>
      <Section title='Overview:'>
        Main project during my 2022 co-op at uAsipre with the aim of bettering the advising program through
        analyzing student text messages. The analysis took a look at the impact of the Covid-19 pandemic
        and searching for any changes to what high school and college students are texting to uAspire advisors.
        The analysis was conducted on past text messages stored at uAspire's database using a script written in
        R programming language. For privacy reasons, no text messages will be disclosed here.
      </Section>
      <img className='one-img' src={`${IMAGE_PATH}message_app.png`} alt="fig_1" />
      
      <Section title='Method:'>
        This analysis uses text message data from uAspire's advisory program participants in high school and college
        across the Bay Area, New York, and Massachusetts from 2018 to 2021. The analysis then searched for the top 20 
        most frequent words for each year.. Then track those  words across the 12 months for each year in the form of a 
        bar graph. The graph will also track pandemic related words such as: "Covid", "Virtual", etc.
      </Section>

      <Section title="Process:"> 
        1. Only keep messages from students that had send more than 5 messages. <br />
        2. Remove symbols and numbers. <br />
        3. Merge frequency of words that shares the same roots. <br />
        4. Stop word filtering: filter out irrelevant words such as: to, the, etc. <br />
        5. Custom stop words such as advisor name and generic school related terms. <br />
        6. Create an inclusion list consisting of the top 20 most frequent words. <br />
        7. Generate bar graphs for each month.
      </Section>
      <img className='one-img' src={`${IMAGE_PATH}example_chart.png`} alt="fig_2" />

      <Section title='Outcome:'>
        By comparing the graphs from before and after the pandemic not much has changed except for 
        the obvious appearance of pandemic related terms. However, looking at the monthly trend 
        across every year we observed the months where students are more likely to ask about
        financial aid. This observation can be used to better prepare the advisor for financial 
        aid related questions in those months. 
      </Section>
      <hr />
    </>
  );
};

export default TextAnalysis; 