import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  projectsRef: null,
  resumeRef: null
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setRef: (state, action) => {
      state.projectsRef = action.payload.projectsRef;
      state.resumeRef = action.payload.resumeRef;
    },
    scrollToRef: (state, action) => {
      window.scrollTo({
        top: state[action.payload].current.offsetTop,
        behavior: "smooth",
      });
    },
    scrollToTop: () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  },
});

export const { setRef, scrollToRef, scrollToTop } = navigationSlice.actions;
export default navigationSlice.reducer;