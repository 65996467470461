import { Link } from 'react-router-dom';
import './style.css'
import { FaLinkedin } from "react-icons/fa";

function About() {
  const email = "Chen.ev@northeastern.edu";
  const linkedin = "https://www.linkedin.com/in/evan-chen-764793271";

  return (
    <div className='about-container'>
      <h2 style={{textAlign: "center"}}> About Me </h2>
      <hr />
      <p style={{maxWidth: '650px'}}>
        Hello, I'm Evan Chen a software engineer with a passion for web development and data. I graduated
        from Northeastern University, learning various skill in computer science, and is constantly seeking 
        new ways to improve my skills. My interest in web development began when I took a web development 
        course at my university. I've always loved visual design, and web development felt like the perfect 
        way to bring my ideas to life. I have fun building and seeing the site I'm working on come together. 
        My hobby includes video game, art and designing/practicing with unpublished react projects.
      </p>
      <hr />
      <div style={{textAlign: "center"}}>
        <div>
          {email}
        </div>
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="fs-2" />
        </a>
      </div>
    </div>
  );
};

export default About;