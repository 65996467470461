import {HashRouter} from "react-router-dom";
import {Routes, Route, Navigate} from "react-router";
import { Provider } from "react-redux";
import store from "./PersonalSite/Store";
import PersonalSite from "./PersonalSite";

function App() {
  return (
    <HashRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/"         element={<Navigate to="/Main"/>}/>
          <Route path="/Main/*"   element={<PersonalSite/>}/>
        </Routes>
      </Provider>
    </HashRouter>
  );
}

export default App;
