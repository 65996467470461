import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "../Navigation/navigationReducer"

export interface KanbasState {
  navigationReducer: {
    projectsRef: any;
    resumeRef: any;
  };
}
const store = configureStore({
  reducer: {
    navigationReducer,
  },
});

export default store;