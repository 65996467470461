import './style.css';

function Resume() {
  return (
    <div className='resume-container'>
      <img src="./Images/Chen, Evan - Resume.jpg" alt='resume'/>
    </div>
  );
};

export default Resume;