import "./style.css";
import { useDispatch } from "react-redux";
import { scrollToRef, scrollToTop } from "./navigationReducer";
import { Link } from "react-router-dom";

const listOfLink = [
  { label: "About"},
  { label: "Projects"},
  { label: "Resume" }
];

function Navigation() {
  return (
    <div className="navigation">
      <div className="button-list">
        {listOfLink.map((link) => (
          <Link to={`/Main/${link.label}`}>  
            <button>{link.label}</button>
          </Link>
        ))}
      </div>
    </div>
  );
};

function HNavigation() {
  const dispatch = useDispatch();

  return (
    <div className="h-navigation">
      <div className="h-button-list">
        {listOfLink.map((link) => (
          <Link to={`/Main/${link.label}`}>  
            <button>{link.label}</button>
          </Link>
        ))}
      </div>
    </div>
  );
};

export {Navigation, HNavigation};