import { Link } from 'react-router-dom';
import './../style.css';
import Section from '../../Components/Section';

function Kanbas() {
  const IMAGE_PATH = '/Images/Kanbas/';

  return (
    <>
      <Section title="Overview:">
        The core project for Northeastern University's CS4550 class where each students are task to create a copy of 
        Instructure's LMS: Canvas. The copy, Kanbas, mimics some of the original's functionality in order to 
        learn how to create a full stack application. Kanbas was created utilizing React, Redux, RESTFUL 
        API, Express.js and MongoDB server. Pages implemented: <br/>
        - Account<br/>
        - Dashboard<br/>
        - Courses: Home<br/>
        - Courses: Module<br/>
        - Courses: Assignments<br/>
        - Courses: Quizzes<br />
        <br />

        Sign in using the following credential to see the full site and please don't input any actual emails. 
        The server may take a minute to boot up. <br/><br/>  
        Username: Guest<br/>
        Password: Guest123<br/>
        Kanbas:&nbsp;   
        <Link to={`https://main--extraordinary-twilight-d7cbc4.netlify.app/#/Kanbas/Dashboard`}>
          https://main--extraordinary-twilight-d7cbc4.netlify.app/#/Kanbas/Dashboard
        </Link>
      </Section>
      <img className='one-img' src={`${IMAGE_PATH}fig_1.png`} alt="fig_1" />

      <Section title="Quiz functionality group project:">
        For this project, I collaborated with two classmates to implement 5 key pages of the quizzes tab from 
        the instructor's perspective. These pages include the quiz list, quiz details, detail editor, 
        questions editor, and quiz preview.  My primary responsibility was to implement the questions editor, 
        which supports three types of questions: fill in the blank, multiple choice, and true/false. Users 
        have the flexibility to edit the title, type, points, answer, and, of course, the question itself.
        All question data is stored in a MongoDB database for data management and retrieval.
      </Section>
      <img className='one-img' src={`${IMAGE_PATH}fig_2.png`} alt="fig_2" />

      <Section title="Quiz database:">
        To ensure ease of implementation I opted for a simpler database structure. This decision
        was made out of respect for my teammates' busy schedules. As shown in the left figure, 
        the question table covers all 3 types and option table was used for both type of options 
        found in fill in blank and multiple choices questions. Although not the best design, it is 
        sufficient for this project. The second figure is how I would redesign the database.  
      </Section>
      <div className='row two-img'>
        <img className='col-lg-12 col-xl-6' src={`${IMAGE_PATH}erd_1.png`} alt="erd_1" />
        <img className='col-lg-12 col-xl-6' src={`${IMAGE_PATH}erd_2.png`} alt="erd_2" />
      </div>
      <hr />
    </>
  );
};

export default Kanbas; 