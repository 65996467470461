import { motion } from 'framer-motion';
import { FaArrowDown } from "react-icons/fa";

function AnimatedDownArrow() {
  const arrowVariants = {
    animate: {
      y: [0, 10, 0],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "loop" as const
      }
  }};

  return (
    <motion.div variants={arrowVariants} animate="animate">
      <FaArrowDown />
    </motion.div>
  );
};

export default AnimatedDownArrow;